<template>
  <div class="w-full px-5 py-5">
    <div
      class="w-full flex flex-col gap-y-2 py-5 bg-greyscale-1 px-5 rounded-[5px]"
    >
      <div class="py-2 border-b border-dashed border-greyscale-3">
        <div class="text-text-secondary text-sm mb-1">You are billed</div>
        <div class="text-primary font-[800] text-lg">
          {{ quote.billing_amount.currency }}
          {{
            formatAmountToMajor(
              quote.billing_amount.amount,
              quote.billing_amount.currency,
            )
          }}
        </div>
      </div>
      <div class="py-2">
        <div class="text-text-secondary text-sm mb-1">You receive</div>
        <div class="text-primary font-[800] text-xl">
          {{ quote.destination_amount.currency }}
          {{
            formatAmountToMajor(
              quote.destination_amount.amount,
              quote.destination_amount.currency,
            )
          }}
        </div>
      </div>

      <ul class="w-full flex flex-col gap-y-3">
        <li
          v-if="quote.exchange_rate && isExchangeRateMajor"
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Exchange rate</div>
          <div class="text-primary text-right">
            1 {{ quote?.destination_amount.currency }} ≈
            {{ quote.exchange_rate }}
            {{ quote?.billing_amount.currency }}
          </div>
        </li>

        <li
          v-else-if="quote.exchange_rate && !isExchangeRateMajor"
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Exchange rate</div>
          <div class="text-primary text-right">
            1 {{ quote?.destination_amount.currency }} ≈
            {{ (1 / Number(quote.exchange_rate)).toFixed(0) }}
            {{ quote?.billing_amount.currency }}
          </div>
        </li>

        <li
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Transaction fees</div>
          <div class="text-primary text-right">
            {{ quote.total_fees.currency }}
            {{
              formatAmountToMajor(
                quote.total_fees.amount || 0,
                quote.total_fees.currency || "",
              )
            }}
          </div>
        </li>
      </ul>
    </div>

    <div class="flex flex-col gap-y-3 mt-5">
      <app-button
        :loading="loading"
        variant="primary"
        size="lg"
        @click="makePayment"
        >Confirm</app-button
      >
      <app-button variant="outlined" size="lg" @click="cancelPayment"
        >Cancel</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { BankingSwapTransactionQuoteResponse } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  cancelPayment: () => void;
  makePayment: () => void;

  quote: BankingSwapTransactionQuoteResponse;

  loading: boolean;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.quote.exchange_rate) {
    return Number(props.quote.exchange_rate) * 100 >= 1;
  }

  return false;
});
</script>
