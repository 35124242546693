<template>
  <div class="w-full">
    <apexchart
      type="area"
      height="380"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";

interface TGraph {
  graphLabels: string[];
  outboundData: number[] | string[];
  inboundData: number[] | string[];
  chartId: string;
  currency: string;
  timerange: "last_week" | "last_6_months" | "last_year";
}

const props = defineProps<TGraph>();

const options = computed(() => ({
  chart: {
    type: "area",
    forecolor: "#2f2f2f",
    background: "#fff",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ["#297FB0", "#B96B6B"],
  grid: {
    show: false,
  },
  tooltip: {
    followCursor: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    custom: function ({ series, seriesIndex, dataPointIndex }: any) {
      return `<div class="w-full bg-primary border-0 rounded-sm text-white font-medium text-sm leading-0 flex flex-col justify-center py-2 px-3 overflow-visible">
          <div style = "display: block">${props.graphLabels[dataPointIndex]}</div>
      <div>
            ${props.currency} ${new Intl.NumberFormat().format(
              series[seriesIndex][dataPointIndex],
            )} 
            </div>
            <div style = "display: block">${seriesIndex === 0 ? "Inbound" : "Outbound"}</div>
          
        </div>`;
    },
    y: {
      formatter: undefined,
      title: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (seriesName: any) => `${props.currency}${seriesName}`,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  labels: props.graphLabels,
  legend: {
    horizontalAlign: "center",
    offsetY: 5,
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: ["#615E83"],
        fontFamily: "Avenir, sans-serif",
        fontWeight: 400,
        fontSize: "12px",
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      
    },
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        colors: ["#615E83"],
        fontFamily: "Avenir, sans-serif",
        fontWeight: 400,
        fontSize: "10px",
      },
    },

    tooltip: {
      enabled: false,
    },
  },
}));

const series = computed(() => [
  {
    name: "Inbound",
    data: props.inboundData,
  },
  {
    name: "Outbound",
    data: props.outboundData,
  },
]);
</script>
