<template>
  <div class="w-full h-fit py-3 bg-primary/90 rounded-[10px]">
    <div
      v-if="exchangeRate"
      class="w-full px-8 py-3 border-b border-white/60 border-dashed"
    >
      <div v-if="isExchangeRateMajor">
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-base">
          {{ exchangeRate }}
          {{ destinationAmount?.currency }} ≈ 1 {{ sourceAmount?.currency }}
        </div>
      </div>
      <div v-else>
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-base">
          {{ (1 / Number(exchangeRate)).toFixed(0) }}
          {{ destinationAmount?.currency }} ≈ 1 {{ sourceAmount?.currency }}
        </div>
      </div>
    </div>

    <div
      v-if="sourceAmount"
      class="w-full px-8 py-3 border-b border-white/60 border-dashed"
    >
      <div class="text-white/80 mb-1 text-sm">
        Your {{ sourceAmount.currency }} account will be debited
      </div>
      <div class="text-white text-base">
        {{ sourceAmount.currency }}
        {{ formatAmount(sourceAmount.amount || 0) }}
      </div>
    </div>

    <div
      v-if="destinationAmount"
      class="w-full px-8 py-3 border-white/60 border-dashed"
    >
      <div class="text-white/80 mb-1 text-sm">
        Your {{ destinationAmount.currency }} account will be credited
      </div>
      <div class="text-secondary text-lg font-bold">
        {{ destinationAmount.currency }}
        {{ formatAmount(destinationAmount.amount || 0) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmount } from "@/helpers";
import { Money } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  sourceAmount?: Money<string>;
  destinationAmount?: Money<string>;
  exchangeRate: string;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.exchangeRate) {
    return Number(props.exchangeRate) * 100 >= 1;
  }

  return false;
});
</script>
