import apiClient, { cryptoUrl, useRequestOptions } from "@/helpers/apiClient";
import {
  BeneficiaryResponse,
  NgnBanksData,
  PaginatedResponse,
  QueryKeys,
  RequestOptions,
  ServiceType,
  SupportedCountriesData,
  SupportedPaymentMethodsData,
} from "@/types";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";

export const useGetBeneficiaries = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<BeneficiaryResponse>>({
    queryKey: [
      QueryKeys.BANKING_BENEFICIARIES,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`v1/beneficiaries?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
    enabled,
  });
};

export const useGetBeneficiary = (id: string, enabled: boolean = true) => {
  return useQuery<BeneficiaryResponse>({
    queryKey: [QueryKeys.SINGLE_BANKING_BENEFICIARY, id],
    queryFn: () =>
      apiClient.get(`v1/beneficiaries/${id}`).then((res) => res.data.data),
    enabled,
  });
};

export const useGetSupportedCountries = (
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<SupportedCountriesData[]>({
      queryKey: [QueryKeys.CRYPTO_SUPPORTED_COUNTRIES],
      queryFn: () =>
        apiClient
          .get(cryptoUrl(`beneficiaries/supported-countries`))
          .then((res) => res.data.data),
      enabled,
    });
  }
  return useQuery<SupportedCountriesData[]>({
    queryKey: [QueryKeys.BANKING_SUPPORTED_COUNTRIES],
    queryFn: () =>
      apiClient
        .get(`v1/beneficiaries/supported-country-methods`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetSupportedPaymentMethods = (
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<SupportedPaymentMethodsData[]>({
      queryKey: [QueryKeys.CRYPTO_SUPPORTED_PAYMENT_METHODS],
      queryFn: () =>
        apiClient
          .get(cryptoUrl(`beneficiaries/supported-payment-methods`))
          .then((res) => res.data.data),
      enabled,
    });
  }

  return useQuery<SupportedPaymentMethodsData[]>({
    queryKey: [QueryKeys.BANKING_SUPPORTED_PAYMENT_METHODS],
    queryFn: () =>
      apiClient
        .get(`v1/beneficiaries/supported-payment-methods`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetNgnBanks = (enabled: boolean = true) => {
  return useQuery<NgnBanksData[]>({
    queryKey: [],
    queryFn: () =>
      apiClient.get(`v1/data/ngn/banks`).then((res) => res.data.data),
    enabled,
  });
};
