<template>
  <div class="w-full">
    <div class="mt-6 mb-3">
      <transaction-status
        :status="request.state === 'READY' ? 'PENDING' : request.state"
      />
    </div>
    <div
      v-if="showSuccessBanner"
      class="flex flex-col justify-start gap-y-2 items-center text-sm mt-8 bg-greyscale-1 py-8 rounded-[5px]"
    >
      <green-check-icon />
      <span v-if="request.state === 'COMPLETED'"
        >Your account has being provisioned</span
      >
      <span v-else>Your account is being provisioned</span>

      <app-button variant="primary" size="sm" @click="handleClose"
        >Continue</app-button
      >
    </div>

    <div v-else-if="request.tasks.length" class="w-full mt-8">
      <p class="mb-3 text-sm text-text-primary">
        Complete the tasks below to provision your account
      </p>

      <div class="w-full flex flex-col gap-x-5">
        <div
          class="flex flex-row justify-start items-center gap-x-5 mb-8 border-b py-2"
        >
          <div
            v-for="(task, index) in request.tasks"
            :key="task.id"
            class="flex flex-col justify-start items-center gap-y-2"
            :class="task.state === 'COMPLETED' ? 'cursor-pointer' : ''"
            @click="moveToSpecificTask(task, index)"
          >
            <div
              class="text-white text-sm rounded-full w-6 h-6 flex justify-center items-center"
              :class="
                task.state === 'CREATED' && index === activeTask
                  ? 'bg-primary'
                  : task.state === 'COMPLETED'
                    ? 'bg-[#0BB34B]'
                    : 'bg-primary/50'
              "
            >
              <check-mark v-if="task.state === 'COMPLETED'" />
              <span v-else>
                {{ index + 1 }}
              </span>
            </div>
            <div
              class="text-xs text-right"
              :class="
                index === activeTask
                  ? 'font-bold text-primary'
                  : 'font-normal text-primary/50'
              "
            >
              {{ getTaskTitle(task?.type) }}
            </div>
          </div>
        </div>

        <div class="w-full">
          <request-account-otp-task
            v-if="
              [
                AccountRequestTaskType.EMAIL_OTP,
                AccountRequestTaskType.PHONE_OTP,
              ].includes(getActiveTask(request.tasks)?.type)
            "
            :task="getActiveTask(request.tasks)"
            :move-to-next="moveToNext"
          />
          <request-account-agreement-task
            v-else-if="
              [
                AccountRequestTaskType.WAZA_AGREEMENT,
                AccountRequestTaskType.PARTNER_AGREEMENT,
                AccountRequestTaskType.AGREEMENT,
              ].includes(getActiveTask(request.tasks)?.type)
            "
            :task="getActiveTask(request.tasks)"
            :move-to-next="moveToNext"
          />
          <request-account-declaration-task
            v-else-if="
              getActiveTask(request.tasks)?.type ===
              AccountRequestTaskType.DECLARATION_OF_SOURCE_OF_FUNDS
            "
            :task="getActiveTask(request.tasks)"
            :move-to-next="moveToNext"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="flex flex-col justify-start gap-y-2 items-center text-sm mt-8 bg-greyscale-1 py-8 rounded-[5px]"
    >
      <green-check-icon />
      <span v-if="request.state === 'COMPLETED'"
        >Your account has being provisioned</span
      >
      <span v-else>Your account is being provisioned</span>

      <app-button variant="primary" size="sm" @click="handleClose"
        >Continue</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  AccountRequestResponse,
  AccountRequestTask,
  AccountRequestTaskType,
  QueryKeys,
} from "@/types";
import { useQueryClient } from "@tanstack/vue-query";
import { ref } from "vue";

const props = defineProps<{
  request: AccountRequestResponse;
  closeModal: () => void;
}>();

const activeTask = ref(0);
const showSuccessBanner = ref(false);
const queryClient = useQueryClient();

const getTaskTitle = (type: AccountRequestTaskType) => {
  switch (type) {
    case AccountRequestTaskType.EMAIL_OTP:
      return "Verify email";
    case AccountRequestTaskType.PHONE_OTP:
      return "Verify phone number";
    case AccountRequestTaskType.WAZA_AGREEMENT:
      return "Accept agreement";
    case AccountRequestTaskType.AGREEMENT:
      return "Accept agreement";
    case AccountRequestTaskType.PARTNER_AGREEMENT:
      return "Accept agreement";
    case AccountRequestTaskType.DECLARATION_OF_SOURCE_OF_FUNDS:
      return "Declaration";
    default:
      return "";
  }
};

const getActiveTask = (tasks: AccountRequestTask[]) => {
  return tasks[activeTask.value];
};

const moveToNext = () => {
  if (activeTask.value < props.request.tasks.length - 1) {
    activeTask.value = activeTask.value + 1;
  } else {
    showSuccessBanner.value = true;
  }

  queryClient.invalidateQueries({
    queryKey: [QueryKeys.ACCOUNT_REQUEST, props.request.id],
  });
};

const moveToSpecificTask = (task: AccountRequestTask, index: number) => {
  if (task.state === "COMPLETED") {
    activeTask.value = index;
  }
};

const handleClose = () => {
  queryClient.invalidateQueries({ queryKey: [QueryKeys.ACCOUNTS] });
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.ACCOUNT_REQUEST, props.request.id],
  });

  props.closeModal();
};
</script>
