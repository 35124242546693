<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2637 11.9288L9.85409 18.3383L9.85409 0.689944L11.5831 0.689944L11.5831 14.1644L15.0412 10.7063L16.2637 11.9288ZM7.26052 17.9804H5.53148L5.53148 4.50595L2.07338 7.96405L0.850945 6.74161L7.26052 0.332031L7.26052 17.9804Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
