<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b-2 border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-sm text-primary font-[800]">
          Add a whitelisted wallet address
        </h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <add-beneficiary-wallet-form
        :create-beneficiary="handleCreateWalletBeneficiary"
        :submitting="submitting"
        :currencies="currencies || []"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useReadResource, useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";
import {
  AvailableAssetsResponse,
  QueryKeys,
  WalletBeneficiaryRequest,
} from "@/types";
import { onMounted } from "vue";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  closeModal: () => void;
}>();

const toast = useAppToast();
const queryClient = useQueryClient();

const { fetch: fetchCurrencies, data: currencies } = useReadResource<
  Array<AvailableAssetsResponse>
>(`/crypto/v1/data/supported-assets`);

const { submitting, execute: createWalletBeneficiary } = useWriteResource(
  "v1/beneficiaries/crypto",
  "post",
  {
    successTitle: "Beneficiary created",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCreateWalletBeneficiary = async (
  values: WalletBeneficiaryRequest,
) => {
  await createWalletBeneficiary({
    body: values,
  });
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.BANKING_BENEFICIARIES],
  });
  props.closeModal();
};

onMounted(() => {
  fetchCurrencies();
});
</script>
