<template>
  <div class="relative bg-white overflow-hidden w-full h-full overflow-y-auto">
    <div
      class="w-full bg-greyscale-1 h-28 px-5 py-5 flex justify-center items-center"
    >
      <div class="font-[800] text-4xl text-primary">
        {{ transaction.direction === "OUTBOUND" ? "-" : "+"
        }}{{ transaction.source_amount.currency }}
        {{ formatAmount(transaction.source_amount.value) }}
      </div>
    </div>
    <div class="w-full flex flex-col justify-between py-5">
      <div class="w-full px-5">
        <ul class="mb-5 flex flex-col gap-y-3">
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Date</div>
            <div class="text-primary text-right">
              {{ formatDateTime(transaction.created_date) }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Reference</div>
            <div class="text-primary text-right">
              {{ transaction.id }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Type</div>
            <div class="flex justify-end gap-x-1 items-center">
              <credit-icon v-if="transaction.type === 'DEPOSIT'" />
              <exchange-icon v-else-if="transaction.type === 'EXCHANGE'" />
              <debit-icon v-else />

              <span
                class="capitalize leading-[0px]"
                :class="
                  transaction.type === 'DEPOSIT'
                    ? 'text-[#297FB0]'
                    : transaction.type === 'EXCHANGE'
                      ? 'text-processing/70'
                      : 'text-[#B96B6B]'
                "
                >{{ transaction.type?.toLowerCase() }}</span
              >
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Status</div>
            <div class="text-primary text-right">
              <transaction-status :status="transaction.state" />
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Description</div>
            <div class="text-primary text-right">
              {{ transaction.description }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Sent</div>
            <div class="text-primary text-right">
              {{ transaction.source_amount.currency }}
              {{ formatAmount(transaction.source_amount.value) }}
            </div>
          </li>

          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Total Fees</div>
            <div class="text-primary text-right">
              {{ transaction.fee.currency }}
              {{ formatAmount(transaction.fee.value) }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Received</div>
            <div class="text-primary text-right">
              {{ transaction.destination_amount.currency }}
              {{ formatAmount(transaction.destination_amount.value) }}
            </div>
          </li>
          <li
            v-if="transaction.txn_hash"
            class="w-full justify-between flex gap-x-10 items-start text-sm font-medium"
          >
            <div class="text-text-secondary">Transaction hash</div>
            <div
              class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
            >
              {{ transaction.txn_hash }}
              <button
                v-if="transaction.txn_hash"
                class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                @click="copyHash(transaction.txn_hash)"
              >
                <copy-icon /> Copy
              </button>
            </div>
          </li>
        </ul>
        <h3 class="text-sm font-medium mb-3">SENDER</h3>

        <inbound-transaction-source-details
          v-if="transaction.type === 'DEPOSIT'"
          :transaction="transaction"
        />
        <outbound-transaction-source-details
          v-else-if="transaction.type !== 'DEPOSIT'"
          :transaction="transaction"
        />

        <h3
          v-if="transaction.type === 'DEPOSIT'"
          class="text-sm font-medium mb-3 mt-5"
        >
          ACCOUNT
        </h3>
        <h3
          v-else-if="transaction.type !== 'DEPOSIT'"
          class="text-sm font-medium mb-3 mt-5"
        >
          BENEFICIARY
        </h3>

        <inbound-transaction-beneficiary-details
          v-if="transaction.type === 'DEPOSIT'"
          :transaction="transaction"
        />
        <outbound-transaction-beneficiary-details
          v-else-if="transaction.type !== 'DEPOSIT'"
          :transaction="transaction"
        />

        <div v-if="transaction.documentation?.length" class="w-full mt-5">
          <h3 class="text-sm font-medium mb-3">DOCUMENTS</h3>
          <ul class="w-full flex flex-col gap-y-5">
            <li
              v-for="doc in transaction.documentation"
              :key="doc.id"
              class="w-full justify-between flex gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary capitalize">{{ doc.type }}</div>
              <div
                v-if="doc.file"
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  :disabled="downloadingDoc === doc.id"
                  @click="handleDownloadDocument(doc)"
                >
                  <document-icon /> Download
                </button>
              </div>
              <div
                v-else-if="doc.value"
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                {{ doc.value }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="copyHash(doc.value)"
                >
                  <copy-icon /> Copy
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="
          transaction.state === 'PENDING_APPROVAL' &&
          hasPermission(LyncPermissions.transactions_approve)
        "
        class="w-full px-5 mt-8"
      >
        <div class="flex justify-between gap-x-2">
          <app-button
            size="lg"
            variant="outlined"
            :loading="rejecting"
            :disabled="rejecting"
            @click="rejectRequest"
          >
            Reject payment
          </app-button>
          <app-button
            size="lg"
            variant="primary"
            :loading="approving"
            :disabled="approving"
            @click="approveRequest"
          >
            Approve payment
          </app-button>
        </div>
      </div>

      <div class="flex mt-8 justify-between items-center gap-x-4 px-5">
        <router-link to="/transactions" class="w-full">
          <app-button variant="outlined" size="lg"
            >Go to transactions</app-button
          >
        </router-link>
        <app-button
          v-if="transaction.state === 'COMPLETED'"
          :disabled="downloading"
          :loading="downloading"
          variant="primary"
          size="lg"
          @click="handleDownloadReceipt"
          >Download receipt</app-button
        >
      </div>
    </div>
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { copyToClipboard, formatAmount, formatDateTime } from "@/helpers";
import apiClient, { cryptoUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { useWriteResource } from "@/composables/use-resource";
import {
  CryptoTransactionResponse,
  ITransactionDocumentation,
  QueryKeys,
} from "@/types";
import { ref } from "vue";
import { usePermission } from "@/composables/use-permission";
import { useRoute } from "vue-router";
import { useAppToast } from "@/composables";
import { LyncPermissions } from "@/security/permissions";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  refetch: () => void;
  transaction: CryptoTransactionResponse;
  closeModal: () => void;
}>();

const route = useRoute();
const downloading = ref(false);
const downloadingDoc = ref("");
const { hasPermission } = usePermission();

const toast = useAppToast();
const queryClient = useQueryClient();

const copyHash = (hash: string) => {
  copyToClipboard(hash);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const handleDownloadReceipt = async () => {
  try {
    downloading.value = true;
    const res = await apiClient.get(
      cryptoUrl(`/transactions/${props.transaction.id}/receipt`),
      {
        responseType: "blob",
      },
    );
    downloading.value = false;
    const file = window.URL.createObjectURL(res.data);
    const docUrl = document.createElement("a");
    docUrl.href = file;
    docUrl.setAttribute("download", `receipt-${route.params.id}.pdf`);
    document.body.appendChild(docUrl);
    docUrl.click();
    document.body.removeChild(docUrl);
  } catch (err) {
    downloading.value = false;
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  }
};

const handleDownloadDocument = async (doc: ITransactionDocumentation) => {
  try {
    downloadingDoc.value = doc.id;
    const res = await apiClient.get(
      cryptoUrl(`/transactions/${props.transaction.id}/documents/${doc.id}`),
      {
        responseType: "blob",
      },
    );
    downloadingDoc.value = "";
    const file = window.URL.createObjectURL(res.data);
    const docUrl = document.createElement("a");
    docUrl.href = file;
    docUrl.setAttribute(`${doc.description}`, `-${doc.id}.pdf`);
    document.body.appendChild(docUrl);
    docUrl.click();
    document.body.removeChild(docUrl);
  } catch (err) {
    downloadingDoc.value = "";
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  }
};

const { submitting: approving, execute: approveRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/approve`,
  "patch",
  {
    successTitle: `Request Approval successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CRYPTO_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: rejecting, execute: rejectRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/reject`,
  "patch",
  {
    successTitle: `Request Rejection successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CRYPTO_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);
</script>
