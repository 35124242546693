<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.87122 1.5H13.8057C14.3876 1.5 14.9458 1.73119 15.3573 2.14271C15.7688 2.55422 16 3.11236 16 3.69433V7.62878C15.9999 7.91974 15.8843 8.19876 15.6785 8.40447L8.22547 15.8575C7.81398 16.2689 7.25594 16.5 6.67408 16.5C6.09222 16.5 5.53418 16.2689 5.12269 15.8575L1.64247 12.3773C1.2311 11.9658 1 11.4078 1 10.8259C1 10.2441 1.2311 9.68602 1.64247 9.27453L9.09553 1.82147C9.30124 1.6157 9.58026 1.50006 9.87122 1.5Z"
      fill="#d1d5db"
      stroke="#d1d5db"
      stroke-width="1.0599"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="12.4355" cy="4.86226" r="0.490189" fill="#2F2F2F" />
    <circle
      cx="6.4043"
      cy="10.8589"
      r="1.03744"
      transform="rotate(11.9664 6.4043 10.8589)"
      stroke="#2F2F2F"
      stroke-width="0.635942"
    />
    <circle
      cx="9.38087"
      cy="7.64409"
      r="1.03744"
      transform="rotate(11.9664 9.38087 7.64409)"
      stroke="#2F2F2F"
      stroke-width="0.635942"
    />
    <rect
      x="5.42088"
      y="8.66693"
      width="5.31439"
      height="0.164627"
      transform="rotate(11.9664 5.42088 8.66693)"
      fill="#d1d5db"
      stroke="#2F2F2F"
      stroke-width="0.164627"
    />
  </svg>
</template>
