<template>
  <form class="w-full px-5 pb-6 mt-5 flex flex-col gap-y-4" @submit="onSubmit">
    <app-input
      label="Name"
      type="text"
      v-bind="formFields.name"
      :error-message="errors.name"
      name="name"
      placeholder="Enter beneficiary name"
      required
    />
    <app-input
      label="Wallet Address"
      type="text"
      v-bind="formFields.address"
      :error-message="errors.address"
      name="address"
      placeholder="Enter wallet address"
      required
    />
    <app-select
      label="Currency"
      v-bind="formFields.currency"
      :error-message="errors.currency"
      name="currency"
      required
    >
      <option value="" disabled selected>Select currency</option>
      <option
        v-for="currency in currencyList"
        :key="currency"
        :value="currency"
      >
        {{ currency }}
      </option>
    </app-select>
    <app-select
      label="blockchain"
      v-bind="formFields.blockchain"
      :error-message="errors.blockchain"
      name="blockchain"
      required
    >
      <option value="" disabled selected>Select wallet blockchain</option>
      <option
        v-for="blockchain in blockchainList"
        :key="blockchain"
        :value="blockchain"
      >
        {{ blockchain.toLocaleUpperCase() }}
      </option>
    </app-select>

    <app-button
      :loading="submitting"
      :disabled="submitting"
      variant="primary"
      size="lg"
      type="submit"
      >Add beneficiary</app-button
    >
  </form>
</template>

<script lang="ts" setup>
import { WalletBeneficiaryRequest, AvailableAssetsResponse } from "@/types";
import { useForm } from "vee-validate";
import { computed } from "vue";
import { reactive } from "vue";
import * as yup from "yup";

interface FormFields extends WalletBeneficiaryRequest {}

const props = defineProps<{
  createBeneficiary: (values: WalletBeneficiaryRequest) => void;
  submitting: boolean;
  currencies: AvailableAssetsResponse[];
}>();

const { errors, handleSubmit, defineInputBinds, values } = useForm<FormFields>({
  validationSchema: yup.object({
    name: yup.string().min(5).required().label("Name"),
    address: yup.string().required().label("Address"),
    currency: yup.string().required().label("Currency"),
    blockchain: yup.string().required().label("blockchain"),
  }),
});

const blockchainList = computed(() => {
  if (values.currency) {
    return props.currencies
      .filter((it) => it.currency === values.currency)
      .map((it) => it.blockchain);
  } else {
    return [];
  }
});

const currencyList = computed(() => {
  if (props.currencies) {
    return Array.from(new Set(props.currencies.map((it) => it.currency)));
  } else {
    return [];
  }
});

const onSubmit = handleSubmit(async (values) => {
  await props.createBeneficiary({
    name: values.name,
    address: values.address,
    currency: values.currency,
    blockchain: values.blockchain,
  });
});

const formFields = reactive({
  name: defineInputBinds("name"),
  address: defineInputBinds("address"),
  currency: defineInputBinds("currency"),
  blockchain: defineInputBinds("blockchain"),
});
</script>
