<template>
  <div v-if="exchangeRateQuote.exchange">
    <div
      v-if="isExchangeRateMajor"
      class="text-sm bg-[#B47E300D] text-[#B47E30] px-3 py-1 w-fit font-medium rounded-[5px] -my-3"
    >
      {{ exchangeRateQuote.exchange.rate }}
      {{ exchangeRateQuote.source_amount.currency }} ≈ 1
      {{ exchangeRateQuote.destination_amount.currency }}
    </div>
    <div
      v-else
      class="text-sm bg-[#B47E300D] text-[#B47E30] px-3 py-1 w-fit font-medium rounded-[5px] -my-3"
    >
      {{ (1 / Number(exchangeRateQuote.exchange.rate)).toFixed(0) }}
      {{ exchangeRateQuote.source_amount.currency }} ≈ 1
      {{ exchangeRateQuote.destination_amount.currency }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { QuoteResponse } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  exchangeRateQuote: QuoteResponse;
}>();

const isExchangeRateMajor = computed(() => {
  if (
    props.exchangeRateQuote.exchange &&
    props.exchangeRateQuote.exchange.rate
  ) {
    return Number(props.exchangeRateQuote.exchange.rate) * 100 >= 1;
  }

  return false;
});
</script>
