<template>
  <Listbox v-model="selectedCurrency" :disabled="disabled">
    <div class="relative mt-1 w-full">
      <ListboxLabel class="text-text-secondary text-sm font-medium">{{
        label
      }}</ListboxLabel>
      <ListboxButton
        class="relative w-full h-10 border overflow-hidden border-[#e2e2e2] rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm mt-2 text-left px-4 py-2"
      >
        <div v-if="selectedCurrency" class="flex items-center gap-x-2">
          <asset-type :asset="selectedCurrency" />
          <span class="text-base text-text-primary font-bold">|</span>
          <span class="text-text-primary">{{
            currencyData(selectedCurrency).name
          }}</span>
        </div>
        <span v-else>Choose a currency </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <arrow-down-icon />
        </span>
      </ListboxButton>

      <ListboxOptions
        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-10 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
      >
        <div>
          <ListboxOption
            v-for="currency in currencyList"
            :key="currency"
            v-slot="{ active, selected }"
            :value="currency"
            as="template"
          >
            <li
              class="relative cursor-default select-none py-2 pl-10 pr-4"
              :class="{
                'bg-greyscale-2 text-primary': active,
                'text-text-primary': !active,
              }"
            >
              <div class="flex items-center gap-x-2">
                <asset-type :asset="currencyData(currency).code" no-title />
                <span
                  :class="{
                    'font-medium': selected,
                    'font-normal': !selected,
                  }"
                  >{{ currencyData(currency).name }}</span
                >
              </div>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </div>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { currencyOf } from "@/helpers/currencies";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";

const props = defineProps<{
  supportedCurrencies: Array<string>;
  label: string;
  defaultCurrency: string;
  errorMessage?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "select", value: string): void;
}>();

const selectedCurrency = ref(props.defaultCurrency);

const currencyData = (currency: string) => {
  return currencyOf(currency);
};

const currencyList = computed(() =>
  props.supportedCurrencies.filter((it) => it !== selectedCurrency.value),
);

watch(selectedCurrency, (it) => {
  if (it) {
    emit("select", it);
  }
});
</script>
