<template>
  <div class="w-full">
    <div
      class="w-full flex flex-col xl:flex-row gap-y-5 gap-x-5 xl:items-end mb-12"
    >
      <div
        class="w-full flex flex-col md:flex-row gap-y-3 gap-x-5 items-center md:items-end min-w-[75%]"
      >
        <select-fx-currency
          label="From"
          :supported-currencies="
            supportedCurrencies.filter((currency) => currency !== quoteCurrency)
          "
          :default-currency="baseCurrency"
          @select="handleSelectedBaseCurrency"
        />
        <div class="hidden md:block px-8 bg-greyscale-2 py-3 rounded-[50px]">
          <plain-swap-icon />
        </div>
        <select-fx-currency
          label="To"
          :supported-currencies="
            supportedCurrencies.filter((currency) => currency !== baseCurrency)
          "
          :default-currency="quoteCurrency"
          @select="handleSelectedQuoteCurrency"
        />
      </div>
      <app-button variant="primary" size="lg" bold @click="handleUpdateRates"
        >View Rates</app-button
      >
    </div>

    <fx-rates-table :currency-pair="currencyPair" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useAppToast } from "@/composables";
import { useFeature } from "@/composables/use-feature";

const supportedCurrencies = ref<string[]>(["USD", "NGN"]);

const { canAccessCenturion } = useFeature();

const toast = useAppToast();

const defaultBase = "NGN";
const defaultQuote = "USD";

const baseCurrency = ref(defaultBase);
const quoteCurrency = ref(defaultQuote);

const currencyPair = ref({
  base: defaultBase,
  quote: defaultQuote,
});

const handleSelectedBaseCurrency = (value: string) => {
  baseCurrency.value = value;
};

const handleSelectedQuoteCurrency = (value: string) => {
  quoteCurrency.value = value;
};

const handleUpdateRates = () => {
  if (baseCurrency.value === quoteCurrency.value) {
    toast.error("Base and Quote currency cannot be the same");
    return;
  }

  currencyPair.value = {
    base: baseCurrency.value,
    quote: quoteCurrency.value,
  };
};

onMounted(() => {
  if (canAccessCenturion.value) {
    supportedCurrencies.value = ["USD", "NGN", "USDT", "USDC"];
  }
});
</script>
