<template>
  <app-layout title="Make Payment" description="Send money to a beneficiary">
    <payments-layout>
      <div class="flex flex-wrap md:flex-nowrap gap-5 xl:gap-x-[60px]">
        <div
          class="w-full max-w-[540px] border border-greyscale-1 px-7 py-8 rounded-[10px]"
        >
          <div class="text-base font-[800] text-primary mb-4">Send From</div>

          <div v-if="isLoading" class="w-full md:w-4/5">
            <skeleton-loader :count="2" />
          </div>
          <div v-else-if="!isError && accounts" class="w-full">
            <RadioGroup v-if="accounts.length" v-model="selectedAccount">
              <RadioGroupLabel class="sr-only"
                >Choose an account</RadioGroupLabel
              >
              <div class="w-full grid grid-cols-2 xl:grid-cols-3 gap-3">
                <RadioGroupOption
                  v-for="it in accounts"
                  :key="it.id"
                  v-slot="{ checked }"
                  as="template"
                  :value="it"
                >
                  <div
                    class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer"
                    :class="
                      checked
                        ? 'bg-greyscale-1 font-[800] border-[3px] border-primary/80'
                        : 'font-medium bg-white border border-greyscale-1'
                    "
                  >
                    <div>
                      <div class="flex items-center gap-x-1 mb-2 text-xs">
                        <asset-type :asset="it.currency" no-title />
                        {{ it.currency }}
                      </div>
                      <div
                        v-if="it.balance"
                        class="text-text-secondary font-medium text-[10px] md:text-xs break-words"
                      >
                        Bal:
                        {{
                          formatAmountToMajor(it.balance.available, it.currency)
                        }}
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div v-else class="text-xs text-primary">
              You've not created any account yet
            </div>
          </div>

          <div v-else-if="isError" class="text-xs text-primary">
            Couldn't fetch your accounts - Contact support
          </div>

          <payment-disclaimer
            v-if="paymentEligibility && !paymentEligibility.supported"
            :disclaimer="`${paymentEligibility.reason || ''}`"
          />

          <payment-disclaimer
            v-if="selectedBeneficiary?.details?.type === 'SWIFT'"
            :disclaimer="`Please ensure destination account can receive this payment in the selected currency`"
          />

          <div class="w-full flex flex-col gap-y-5 mt-8">
            <select-beneficiary-input
              :default-beneficiary="selectedBeneficiary || undefined"
              @select="handleSelectedBeneficiary"
            />

            <app-select
              v-if="
                selectedBeneficiary &&
                selectedBeneficiary.details?.type === 'SWIFT'
              "
              v-bind="formFields.currency"
              name="currency"
              label="Currency"
              :error-message="fieldErrors.currency"
              placeholder="Select currency"
              required
            >
              <option value="" disabled>Select a currency</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </app-select>

            <exchange-rate-display
              v-if="exchangeRateQuote && exchangeRateQuote.exchange_rate"
              :exchange-rate-quote="exchangeRateQuote"
            />

            <amount-input
              v-model="amount"
              name="amount"
              label="Amount"
              :error-message="fieldErrors.amount"
              required
            />

            <app-input
              v-bind="formFields.narration"
              name="narration"
              label="Narration"
              type="text"
              :error-message="fieldErrors.narration"
              placeholder="Enter purpose of payment"
              required
            />

            <file-input
              label="Supporting document"
              :handle-file-change="handleFileChange"
              :file-value="fileValue"
              :error-message="fieldErrors.purpose_of_payment"
              name="invoice"
            />

            <div class="w-full block md:hidden">
              <mobile-banking-transaction-summary
                :account-balance="selectedAccount?.balance?.available"
                :account-currency="selectedAccount?.currency"
                :quote="quote"
              />
            </div>

            <app-button
              :loading="submitting"
              :disabled="submitting || !quote"
              variant="primary"
              size="lg"
              type="submit"
              @click="handleShowConfirmation"
              >Proceed</app-button
            >
          </div>
        </div>
        <banking-transaction-summary
          :account-balance="selectedAccount?.balance?.available"
          :account-currency="selectedAccount?.currency"
          :quote="quote"
        />
      </div>

      <app-modal
        v-if="successfulTxn"
        :is-open="showTxnStatus"
        :handle-close="() => {}"
        size="lg"
      >
        <fiat-transaction-success-modal
          :close-modal="closeTxnStatus"
          :transaction="successfulTxn"
        />
      </app-modal>

      <app-modal
        v-else-if="txnError"
        :is-open="showTxnStatus"
        :handle-close="closeTxnStatus"
        size="lg"
      >
        <transaction-error-modal
          :close-modal="closeTxnStatus"
          :error-message="txnError"
        />
      </app-modal>

      <app-modal
        v-if="quote && selectedBeneficiary && selectedAccount"
        :is-open="showTxnConfirmation"
        :handle-close="closeTxnConfirmation"
        size="lg"
      >
        <fiat-transaction-confirmation
          :loading="loading"
          :quote="quote"
          :close-modal="closeTxnConfirmation"
          :amount="values.amount.toString() || ''"
          :currency="selectedAccount.currency || ''"
          :transaction="successfulTxn"
          :beneficiary="selectedBeneficiary"
          :make-payment="handleSubmit"
          :narration="values.narration"
        />
      </app-modal>
    </payments-layout>
  </app-layout>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, computed } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import {
  BeneficiaryResponse,
  QueryKeys,
  BankingTransactionResponse,
  AccountListResponse,
  BankingQuoteResponse,
  PaymentEligibilityResponse,
} from "@/types";
import { formatAmountToMajor } from "@/helpers";
import { currencyOf } from "@/helpers/currencies";
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { useField, useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useListCorporateAccounts } from "@/data-access/accounts";
import { useDefaultBeneficiary } from "@/composables/states";
import { useDefaultAccount } from "@/composables/states";
import { onBeforeRouteLeave } from "vue-router";
import { debounce } from "lodash";

interface FormFields {
  amount: number;
  narration: string;
  currency?: string;
}

const { defaultAccount, updateDefaultAccount } = useDefaultAccount();
const { data, isLoading, isError } = useListCorporateAccounts();
const toast = useAppToast();
const fileValue = ref<File | null>(null);

const accounts = computed<AccountListResponse[]>(() => {
  if (data.value) {
    return data.value.filter((ac) => !ac.in_request_state).map((it) => it);
  }
  return [];
});

const showTxnStatus = ref(false);
const showTxnConfirmation = ref(false);
const successfulTxn = ref<BankingTransactionResponse>();
const txnError = ref<string>();
const fieldErrors = reactive({
  amount: "",
  narration: "",
  purpose_of_payment: "",
  currency: "",
});
const quote = ref<BankingQuoteResponse>();
const exchangeRateQuote = ref<BankingQuoteResponse>();

const paymentEligibility = ref<PaymentEligibilityResponse | null>(null);

const selectedAccount = ref<AccountListResponse | undefined>(
  accounts.value ? accounts.value[0] : undefined,
);

const { defaultBeneficiary, updateDefaultBeneficiary } =
  useDefaultBeneficiary();
const selectedBeneficiary = ref<BeneficiaryResponse | null>(
  defaultBeneficiary.value as BeneficiaryResponse | null,
);

const { defineInputBinds, values } = useForm<FormFields>();

const { value: amount } = useField<number>("amount");
const formFields = reactive({
  narration: defineInputBinds("narration"),
  currency: defineInputBinds("currency"),
});

const queryClient = useQueryClient();

const closeTxnStatus = () => {
  showTxnStatus.value = !showTxnStatus.value;
};

const closeTxnConfirmation = () => {
  showTxnConfirmation.value = false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleFileChange = (e: any) => {
  const file = e.target.files[0];
  const fileSize = file.size / 1024 / 1024;
  fieldErrors.purpose_of_payment = "";

  if (fileSize > 3) {
    toast.success(
      "File greater than 3mb, Please upload another with a smaller size",
      {
        position: "top-right",
      },
    );
  } else {
    fileValue.value = file;
  }
};

const { submitting: loading, execute: makePayment } = useWriteResource(
  bankingUrl("payments"),
  "post",
  {
    onError: (err) => {
      txnError.value = errorMessage(err);
      showTxnStatus.value = true;
      showTxnConfirmation.value = false;
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: checkPaymentEligibility } = useWriteResource(
  bankingUrl("payments/transfer-eligibility"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting, execute: getQuote } = useWriteResource(
  bankingUrl("quotes/transfers"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleSelectedBeneficiary = (beneficiary: BeneficiaryResponse) => {
  selectedBeneficiary.value = beneficiary;
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }
  if (!values.narration || values.narration.length < 3) {
    fieldErrors.narration = "Narration should contain at least 3 characters";
  }

  return !values.amount || !values.narration || values.narration.length < 3;
};

const handleGetQuote = async () => {
  if (
    paymentEligibility.value?.supported &&
    values.amount &&
    selectedBeneficiary.value &&
    selectedAccount.value
  ) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(selectedAccount.value?.currency || "").precision;

    const res = await getQuote({
      body: {
        source_account_id: selectedAccount.value.id,
        amount: amount,
        counterparty_id: selectedBeneficiary.value.id,
        fixed_side: "destination",
        destination_currency:
          values.currency || selectedBeneficiary.value.currency,
      },
    });
    quote.value = res;

    const currency = values.currency || selectedBeneficiary.value?.currency;
    if (currency !== selectedAccount.value?.currency) {
      exchangeRateQuote.value = res;
    }
  }

  if (!selectedBeneficiary.value) {
    toast.error("Please select a beneficiary", {
      position: "top-right",
    });
  }

  if (!selectedAccount.value) {
    toast.error("Please select an account", {
      position: "top-right",
    });
    return;
  }
};

const handleGetExhangeRate = async () => {
  const currency = values.currency || selectedBeneficiary.value?.currency;

  if (currency !== selectedAccount.value?.currency) {
    const res = await getQuote({
      body: {
        source_account_id: selectedAccount.value?.id,
        amount: 100,
        counterparty_id: selectedBeneficiary.value?.id,
        fixed_side: "destination",
        destination_currency: currency,
      },
    });
    exchangeRateQuote.value = res;
  }
};

const handleShowConfirmation = () => {
  if (!isRequiredFieldsEmpty() && paymentEligibility.value?.supported) {
    showTxnConfirmation.value = true;
  }
};

const handleSubmit = async () => {
  if (quote.value) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(selectedAccount.value?.currency || "").precision;

    const txn = await makePayment({
      body: {
        amount,
        beneficiary_id: selectedBeneficiary.value?.id,
        source_account_id: selectedAccount.value?.id,
        description: values.narration,
        destination_currency:
          values.currency || selectedBeneficiary.value?.currency,
      },
    });
    successfulTxn.value = txn;
    showTxnStatus.value = true;
    showTxnConfirmation.value = false;
    queryClient.invalidateQueries({ queryKey: [QueryKeys.ACCOUNTS] });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.BANKING_TRANSACTIONS],
    });
  }
};

const handleCheckPaymentEligibility = async () => {
  if (selectedBeneficiary.value && selectedAccount.value) {
    const res = await checkPaymentEligibility({
      body: {
        counterparty_id: selectedBeneficiary.value.id,
        source_account_id: selectedAccount.value.id,
      },
    });

    paymentEligibility.value = res;

    if (res.supported) {
      handleGetExhangeRate();
    }
  }
};

watch(
  () => values.amount,
  debounce(() => {
    handleGetQuote();
    fieldErrors.amount = "";
  }, 1000),
);

watch(
  () => values.narration,
  (val) => {
    if (val.length > 3) {
      fieldErrors.narration = "";
    }
  },
);

watch(
  () => values.currency,
  () => {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    handleGetExhangeRate();
    handleGetQuote();
  },
);

watch(accounts, (val) => {
  if (!defaultAccount.value && val && val.length && !selectedAccount.value) {
    selectedAccount.value = val[0];
  } else if (defaultAccount.value && val && val.length) {
    selectedAccount.value = val.filter(
      (acc) => acc.id === defaultAccount.value?.id,
    )[0];
  }
});

watch(selectedBeneficiary, (val) => {
  if (val && selectedAccount.value) {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    formFields.currency.value = val.currency;
    handleCheckPaymentEligibility();
  }
});

watch(selectedAccount, () => {
  quote.value = undefined;
  exchangeRateQuote.value = undefined;
  handleCheckPaymentEligibility();
});

onMounted(() => {
  if (defaultAccount.value && accounts.value && accounts.value.length) {
    selectedAccount.value = accounts.value.filter(
      (acc) => acc.id === defaultAccount.value?.id,
    )[0];
  }
  if (defaultBeneficiary.value) {
    selectedBeneficiary.value = defaultBeneficiary.value as BeneficiaryResponse;
    formFields.currency.value = defaultBeneficiary.value.currency;
  }
});

onBeforeRouteLeave(() => {
  updateDefaultBeneficiary(null);
  updateDefaultAccount(null);
});
</script>
