<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 0.54 0.54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.18 0.225h0.27l-0.09 -0.09"
      stroke="#2f2f2f"
      stroke-width="0.045"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.36 0.315H0.09l0.09 0.09"
      stroke="#2f2f2f"
      stroke-width="0.045"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
