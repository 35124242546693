<template>
  <div
    class="w-full h-auto rounded-[10px] border border-greyscale-1 overflow-hidden xl:w-[80%]"
  >
    <header
      class="flex flex-wrap-reverse md:flex-nowrap gap-y-5 justify-between items-center w-full px-5 pb-2 pt-3 border-b border-greyscale-1"
    >
      <div class="w-full flex items-center gap-x-8">
        <div class="">
          <div class="text-text-secondary text-sm mb-1">Total debits</div>
          <div class="text-base text-primary font-bold">
            {{ currency }}
            {{
              transactionsSummary &&
              formatAmountToMajor(
                transactionsSummary[selected].totals.outbound,
                currency,
              )
            }}
          </div>
        </div>
        <div class="w-[1px] h-12 bg-greyscale-3"></div>
        <div class="">
          <div class="text-text-secondary text-sm mb-1">Total credits</div>
          <div class="text-base text-primary font-bold">
            {{ currency }}
            {{
              transactionsSummary &&
              formatAmountToMajor(
                transactionsSummary[selected].totals.inbound,
                currency,
              )
            }}
          </div>
        </div>
      </div>

      <RadioGroup v-model="selected" class="w-full">
        <RadioGroupLabel class="sr-only">Time Range</RadioGroupLabel>
        <div class="w-full flex justify-end gap-x-2 md:gap-x-3">
          <RadioGroupOption
            v-for="range in timeRanges"
            :key="range.value"
            v-slot="{ checked }"
            as="template"
            :value="range.value"
          >
            <div
              :class="`text-xs cursor-pointer px-2 md:px-4 py-2 flex justify-center items-center rounded-[5px] ${
                checked
                  ? ' text-white font-[800] bg-primary'
                  : 'bg-transparent border border-greyscale-7 text-primary'
              }`"
            >
              {{ range.name }}
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </header>

    <line-chart
      :graph-labels="labels"
      :outbound-data="outboundData"
      :inbound-data="inboundData"
      chart-id="graph"
      :currency="currency"
      :timerange="selected"
    />
  </div>
</template>

<script lang="ts" setup>
import { useGetAccountTransactionsSummary } from "@/data-access/assets";
import { formatAmountToMajor, minorAmountToMajor } from "@/helpers";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { computed } from "vue";
import { ref } from "vue";

type Ttimerange = "last_week" | "last_6_months" | "last_year";

const props = defineProps<{
  accountId: string;
  currency: string;
}>();

const selected = ref<Ttimerange>("last_week");

const { data: transactionsSummary } = useGetAccountTransactionsSummary(
  props.accountId,
);

const labels = computed(() => {
  if (transactionsSummary.value) {
    return transactionsSummary.value[selected.value].data
      .map((item) => item.label)
      .reverse();
  }
  return [];
});

const inboundData = computed(() => {
  if (transactionsSummary.value) {
    return transactionsSummary.value[selected.value].data
      .map((item) => minorAmountToMajor(item.inbound, props.currency))
      .reverse();
  }
  return [];
});

const outboundData = computed(() => {
  if (transactionsSummary.value) {
    return transactionsSummary.value[selected.value].data
      .map((item) => minorAmountToMajor(item.outbound, props.currency))
      .reverse();
  }
  return [];
});

const timeRanges: { name: string; value: Ttimerange }[] = [
  {
    name: "1wk",
    value: "last_week",
  },
  {
    name: "6mo",
    value: "last_6_months",
  },
  {
    name: "1yr",
    value: "last_year",
  },
];
</script>
