<template>
  <div
    class="w-full text-sm md:text-base flex flex-col md:flex-row justify-center md:justify-between items-center min-h-[50px] my-5 gap-y-3"
  >
    <p class="text-text-primary">
      Showing
      {{ showingFirst }}-{{ showingLast }}
      of
      <span class="text-primary font-bold">{{ totalItems }} results</span>
    </p>
    <PaginationRoot
      :total="totalItems"
      :items-per-page="perPage"
      :sibling-count="1"
      :page="currentPage"
      show-edges
      @update:page="currentPage = $event"
    >
      <PaginationList
        v-slot="{ items }"
        as="ul"
        class="flex h-full items-center justify-center gap-x-6"
      >
        <li class="w-fit flex justify-center items-center h-fit p-0 m-0">
          <button
            type="button"
            :disabled="isFirstPage"
            class="disabled:cursor-not-allowed"
            aria-label="Previous"
            @click="prev"
          >
            <circle-arrow-left-icon />
          </button>
        </li>

        <template v-for="(page, index) in items" :key="index">
          <li
            v-if="page.type === 'page'"
            :value="page.value"
            class="w-fit h-fit flex justify-center items-center p-0 m-0"
          >
            <button
              :class="`w-fit h-fit ${
                currentPage === page.value
                  ? 'text-primary font-bold'
                  : 'text-text-primary'
              }`"
              @click="currentPage = page.value"
            >
              {{ page.value }}
            </button>
          </li>
          <PaginationEllipsis
            v-else
            :key="page.type"
            :index="index"
            class="w-fit h-fit flex justify-center items-center p-0 m-0"
          >
            &#8230;
          </PaginationEllipsis>
        </template>

        <li class="w-fit h-fit flex justify-center items-center p-0 m-0">
          <button
            type="button"
            :disabled="isLastPage"
            class="disabled:cursor-not-allowed"
            aria-label="Next"
            @click="next"
          >
            <circle-arrow-right-icon />
          </button>
        </li>
      </PaginationList>
    </PaginationRoot>

    <div class="flex items-center gap-x-2">
      <div class="text-text-primary">Items per page</div>
      <select
        class="h-10 w-[70px] px-2 py-2 border border-[#E2E2E2] rounded-[5px] focus:outline-primary appearance-none bg-select-chevron bg-chevron-position bg-no-repeat bg-auto placeholder:text-s"
        @change="handlePerPageChange"
      >
        <option :selected="perPage === 10" :value="10">10</option>

        <option :selected="perPage === 20" :value="20">20</option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PaginateProps, PaginateChangeParams } from "./table.props";
import { useOffsetPagination } from "@vueuse/core";
import { PaginationEllipsis, PaginationList, PaginationRoot } from "radix-vue";

interface Props extends PaginateProps {}

interface ChangeParams extends PaginateChangeParams {}

const emit = defineEmits<{
  (e: "change", params: ChangeParams): void;
}>();
const props = defineProps<Props>();

const { currentPage, currentPageSize, isFirstPage, isLastPage, prev, next } =
  useOffsetPagination({
    total: props.totalItems,
    page: props.currentPage,
    pageSize: props.perPage,
    onPageChange: (params) =>
      emit("change", {
        perPage: params.currentPageSize,
        currentPage: params.currentPage,
      }),
    onPageSizeChange: (params) =>
      emit("change", {
        perPage: params.currentPageSize,
        currentPage: params.currentPage,
      }),
  });

const showingFirst = computed(() => {
  return isFirstPage.value
    ? 1
    : (currentPage.value - 1) * currentPageSize.value + 1;
});

const showingLast = computed(() => {
  return isLastPage.value
    ? props.totalItems
    : currentPage.value * currentPageSize.value;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handlePerPageChange(e: any) {
  currentPageSize.value = e.target.value;
}
</script>
