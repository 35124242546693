import apiClient, { useRequestOptions } from "@/helpers/apiClient";
import {
  QueryKeys,
  RequestOptions,
  FxRateResponse,
  PaginatedResponse,
  FxRate,
} from "@/types";
import { useQuery } from "@tanstack/vue-query";

export const useGetFxRatesHistory = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<FxRateResponse>>({
    queryKey: [
      QueryKeys.FX_RATES,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`v1/rates/history?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetCurrentFxRates = () => {
  return useQuery<FxRate[]>({
    queryKey: [QueryKeys.CURRENT_FX_RATES],
    queryFn: () =>
      apiClient
        .get(`v1/rates/current`)
        .then((res) => transformRateData(res.data.data)),
  });
};

function transformRateData(rates: FxRateResponse[]): FxRate[] {
  const pairGroups = new Map<string, FxRate>();

  rates.forEach((rate) => {
    const canonicalPair = [rate.base_currency, rate.quote_currency]
      .sort()
      .join("/");

    const currencyCodes = canonicalPair.split("/");

    if (pairGroups.has(canonicalPair)) {
      const existingRateData = pairGroups.get(canonicalPair);

      let buyRate = existingRateData?.buyRate || null;
      let sellRate = existingRateData?.sellRate || null;

      if (!existingRateData?.buyRate) {
        buyRate =
          rate.base_currency === currencyCodes[0] &&
          rate.quote_currency === currencyCodes[1]
            ? getRateValue(rate.rate)
            : null;
      }

      if (!existingRateData?.sellRate) {
        sellRate =
          rate.quote_currency === currencyCodes[0] &&
          rate.base_currency === currencyCodes[1]
            ? getRateValue(rate.rate)
            : null;
      }

      pairGroups.set(canonicalPair, {
        currencyPair: canonicalPair,
        buyRate: buyRate,
        sellRate: sellRate,
        createdDate: rate.created_date,
      });
    } else {
      const buyRate =
        rate.base_currency === currencyCodes[0] &&
        rate.quote_currency === currencyCodes[1]
          ? getRateValue(rate.rate)
          : null;
      const sellRate =
        rate.quote_currency === currencyCodes[0] &&
        rate.base_currency === currencyCodes[1]
          ? getRateValue(rate.rate)
          : null;

      pairGroups.set(canonicalPair, {
        currencyPair: canonicalPair,
        buyRate: buyRate,
        sellRate: sellRate,
        createdDate: rate.created_date,
      });
    }
  });

  return Array.from(pairGroups.values());
}

const getRateValue = (rate: number): number => {
  if (rate * 100 >= 1) {
    return rate;
  } else {
    return Number((1 / rate).toFixed(0));
  }
};
