<template>
  <div
    class="w-full xl:w-[40%] max-w-[400px] bg-primary text-white pb-6 rounded-[10px] overflow-hidden"
  >
    <header
      class="flex justify-between items-center px-6 py-4 border-b border-white/10"
    >
      <h4 class="text-sm font-medium text-white/8-">
        {{ account.currency }} Account Details
      </h4>

      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
            class="flex h-[35px] items-center rounded-[20px] font-medium bg-white px-3 py-2 gap-x-2 text-primary text-xs justify-between truncate"
          >
            {{
              `${
                account.currency === "NGN"
                  ? `${activeTypeDetails.data.bank_name}`
                  : `${snakeCaseToWords(activeTypeDetails.type)}`
              }`
            }}
            <arrow-down-icon class="min-w-[13px]" />
          </MenuButton>
        </div>
        <MenuItems
          class="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[20]"
        >
          <div class="p-1 flex flex-col gap-y-1">
            <MenuItem
              v-for="(item, index) in account.deposit_addresses"
              :key="item.toString()"
            >
              <button
                class="flex w-[120px] items-center gap-x-2 px-2 py-2 text-primary text-xs text-left border-b border-greyscale-7"
                @click="() => setActiveType(index)"
              >
                {{
                  `${
                    account.currency === "NGN"
                      ? `${item.data.bank_name}`
                      : `${snakeCaseToWords(item.type)}`
                  }`
                }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </header>

    <div class="w-full flex flex-col gap-y-4 mt-4">
      <div
        v-for="it in Object.keys(activeTypeDetails.data).filter(
          (me) => me !== 'bank_code',
        )"
        :key="it"
        class="w-full px-5"
      >
        <div
          v-if="
            activeTypeDetails.data[it as keyof AccountDepositAddressData] &&
            typeof activeTypeDetails.data[
              it as keyof AccountDepositAddressData
            ] === 'object'
          "
          class="w-full flex flex-col gap-y-4"
        >
          <div
            v-for="me in Object.keys(
              activeTypeDetails.data[it as keyof AccountDepositAddressData],
            )"
            :key="me"
            class="w-full flex justify-between items-center"
          >
            <div class="max-w-[200px]">
              <div class="text-xs text-white/80 mb-1">
                {{ convertToWords(it) }} {{ convertToWords(me) }}
              </div>
              <div class="text-white text-sm font-medium capitalize">
                {{
                  activeTypeDetails.data[it as keyof AccountDepositAddressData][
                    me as any
                  ]
                }}
              </div>
            </div>
            <button
              class="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-greyscale-2/90"
              @click="
                copyText(
                  activeTypeDetails.data[it as keyof AccountDepositAddressData][
                    me as any
                  ] || '',
                )
              "
            >
              <copy-icon />
            </button>
          </div>
        </div>
        <div v-else class="w-full flex justify-between items-center">
          <div class="max-w-[200px]">
            <div class="text-xs text-white/80 mb-1">
              {{ convertToWords(it) }}
            </div>
            <div class="text-white text-sm font-medium capitalize">
              {{
                activeTypeDetails.data[it as keyof AccountDepositAddressData]
              }}
            </div>
          </div>
          <button
            class="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-greyscale-2/90"
            @click="
              copyText(
                activeTypeDetails.data[it as keyof AccountDepositAddressData] ||
                  '',
              )
            "
          >
            <copy-icon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { copyToClipboard, convertToWords, snakeCaseToWords } from "@/helpers";
import { AccountDepositAddressData } from "@/types";
import { AccountsResponse } from "@/types";
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import { computed } from "vue";
import { ref } from "vue";
import { useAppToast } from "@/composables";

const props = defineProps<{
  account: AccountsResponse;
}>();

const toast = useAppToast();
const activeType = ref(0);

const activeTypeDetails = computed(() => {
  return props.account.deposit_addresses[activeType.value];
});

const setActiveType = (index: number) => {
  activeType.value = index;
};

const copyText = (text: string) => {
  copyToClipboard(text);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};
</script>
