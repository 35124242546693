<template>
  <label class="w-full">
    <div
      class="w-full flex gap-y-[9px]"
      :class="alignRow ? 'flex-col md:flex-row gap-x-3' : 'flex-col'"
    >
      <div
        class="flex justify-between items-center"
        :class="alignRow ? 'w-fit min-w-max' : 'w-full'"
      >
        <span
          class="text-sm font-medium w-fit min-w-fit"
          :class="greyText ? 'text-primary/70' : 'text-primary'"
          >{{ label }}</span
        >
        <span
          v-if="showRequiredLabel && !alignRow"
          class="text-sm text-[#BFC0BA] w-fit min-w-fit"
          >{{ required ? "Required" : "Optional" }}</span
        >
      </div>

      <input
        ref="inputRef"
        :class="`w-full h-10 border ${
          errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
        }  px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm`"
        :value="inputValue"
        :required="required"
        placeholder="0.00"
        type="text"
        inputmode="decimal"
        @input="handleInput"
        @keydown="validateInput"
      />
    </div>
    <span class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
      ><caution-icon v-if="errorMessage" class="fill-failed min-w-[24px]" />
      {{ errorMessage && errorMessage }}</span
    >
  </label>
</template>

<script lang="ts">
export default {
  name: "AmountInput",
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from "vue";

interface Props {
  modelValue?: number;
  label?: string;
  required?: boolean;
  showRequiredLabel?: boolean;
  errorMessage?: string;
  alignRow?: boolean;
  greyText?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", value: number | undefined): void;
}>();

const inputValue = ref("");
const inputRef = ref<HTMLInputElement | null>(null);

const MAX_DECIMAL_PLACES = 2;

const formatAmount = (value: string): string => {
  // Remove any non-digit characters except decimal point
  let cleanValue = value.replace(/[^\d.]/g, "");

  // Ensure only one decimal point
  const parts = cleanValue.split(".");
  cleanValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = cleanValue.split(".");

  // Format integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine integer and decimal parts
  if (decimalPart !== undefined) {
    return `${formattedInteger}.${decimalPart.slice(0, MAX_DECIMAL_PLACES)}`;
  }

  return formattedInteger;
};

const setCursorPosition = (cursorPosition: number) => {
  if (!inputRef.value) return;

  setTimeout(() => {
    inputRef.value?.setSelectionRange(cursorPosition, cursorPosition);
  }, 0);
};

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const cursorPosition = target.selectionStart || 0;
  const oldValue = inputValue.value;

  const formattedValue = formatAmount(target.value);

  inputValue.value = formattedValue;

  // Calculate new cursor position
  const oldCommaCount = (oldValue.slice(0, cursorPosition).match(/,/g) || [])
    .length;
  const newCommaCount = (
    formattedValue.slice(0, cursorPosition).match(/,/g) || []
  ).length;
  const newPosition = cursorPosition + (newCommaCount - oldCommaCount);

  setCursorPosition(newPosition);

  if (formattedValue === "") {
    emit("update:modelValue", undefined);
  } else {
    const numericValue = parseFloat(formattedValue.replace(/,/g, ""));
    emit("update:modelValue", isNaN(numericValue) ? undefined : numericValue);
  }
};

const validateInput = (event: KeyboardEvent) => {
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
  ];

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
    return;
  }

  // Prevent multiple decimal points
  if (event.key === "." && inputValue.value.includes(".")) {
    event.preventDefault();
  }

  // Prevent more than MAX_DECIMAL_PLACES after the decimal point
  if (inputValue.value.includes(".")) {
    const [, decimalPart] = inputValue.value.split(".");
    if (
      decimalPart &&
      decimalPart.length >= MAX_DECIMAL_PLACES &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      const cursorPosition =
        (event.target as HTMLInputElement).selectionStart || 0;
      const decimalPointIndex = inputValue.value.indexOf(".");
      if (cursorPosition > decimalPointIndex) {
        event.preventDefault();
      }
    }
  }
};

const formattedModelValue = computed(() => {
  return props.modelValue !== undefined
    ? formatAmount(props.modelValue.toString())
    : "";
});

watch(formattedModelValue, (newValue) => {
  if (inputValue.value !== newValue) {
    inputValue.value = newValue;
  }
});

onMounted(() => {
  inputValue.value = formattedModelValue.value;
});
</script>
