<template>
  <div class="w-full">
    <h2 class="mb-5 font-bold text-sm text-primary">
      {{ currencyPair.base }} - {{ currencyPair.quote }} Rates history
    </h2>
    <app-table
      v-if="!isError"
      :data="data?.items || []"
      :columns="columns"
      :loading="isLoading"
      :pagination="{
        currentPage,
        perPage,
        totalItems: data?.total_items || 0,
      }"
      @change="handleTableChange"
    >
      <template #column-currency="record">
        <div class="font-semibold">
          {{ record.row.base_currency }} - {{ record.row.quote_currency }}
        </div>
      </template>
      <template #column-rate="record">
        <div class="">
          {{ getRateValue(record.row.rate) }}
        </div>
      </template>

      <template #column-date="record">
        <div class="">
          {{ formatDateTime(record.row.created_date) }}
        </div>
      </template>

      <template #column-state="record">
        <user-status :status="record.row.state" />
      </template>

      <template #empty-state>
        <div
          class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
        >
          <empty-data />

          <p class="text-base text-center text-text-primary">
            {{
              ` Rates history for ${baseCurrency} to ${quoteCurrency} not available at the moment`
            }}
          </p>
        </div>
      </template>
    </app-table>
  </div>
</template>

<script lang="ts" setup>
import { FxRateResponse } from "@/types";
import { ref, watch } from "vue";
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { useGetFxRatesHistory } from "@/data-access/fx-rates";
import { formatDateTime } from "@/helpers";

const props = defineProps<{
  currencyPair: {
    base: string;
    quote: string;
  };
}>();

const currentPage = ref(1);
const perPage = ref(10);

const baseCurrency = ref(props.currencyPair.base);
const quoteCurrency = ref(props.currencyPair.quote);

const { data, isLoading, isError } = useGetFxRatesHistory({
  page: currentPage,
  limit: perPage,
  filters: {
    base_currency: baseCurrency,
    quote_currency: quoteCurrency,
  },
});

watch(
  () => props.currencyPair,
  (value) => {
    baseCurrency.value = value.base;
    quoteCurrency.value = value.quote;
  },
);

const getRateValue = (rate: number) => {
  if (rate * 100 >= 1) {
    return rate;
  } else {
    return (1 / rate).toFixed(0);
  }
};

const columns: TableColumn<FxRateResponse>[] = [
  {
    label: "Currency Pair",
    dataIndex: "currency",
    selector: () => {},
    showOnMobile: false,
  },
  {
    label: "Rate",
    dataIndex: "rate",
    selector: () => {},
    showOnMobile: false,
  },

  {
    label: "State",
    dataIndex: "state",
    selector: (row) => row.state,
    showOnMobile: false,
  },
  {
    label: "Date",
    dataIndex: "date",
    selector: () => {},
    showOnMobile: false,
  },

  {
    label: "Currency",
    dataIndex: "currency",
    selector: () => {},
    showOnMobile: true,
  },
  {
    label: "Rate",
    dataIndex: "rate",
    selector: () => {},
    showOnMobile: true,
  },
  {
    label: "State",
    dataIndex: "state",
    selector: () => {},
    showOnMobile: true,
  },
];

const handleTableChange = (params: TableChangeParams) => {
  (perPage.value = params.perPage), (currentPage.value = params.currentPage);
};
</script>
