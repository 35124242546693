<template>
  <app-modal
    :is-open="isModalOpen"
    :handle-close="closeModal"
    size="xl"
    align-right
  >
    <div class="relative w-full h-full bg-white overflow-y-auto">
      <page-loader v-if="isLoading" />
      <div v-else-if="beneficiary" class="w-full px-5 py-8">
        <h2 class="text-lg mb-5 font-bold capitalize flex gap-x-2">
          <institution-icon v-if="beneficiary.type === 'FIAT'" />
          <wallet-icon v-else />

          <span>{{
            beneficiary?.name?.toLowerCase() ||
            beneficiary?.friendly_name?.toLowerCase()
          }}</span>
        </h2>
        <ul class="w-full flex flex-col gap-y-3 mb-8 mt-10">
          <li
            v-if="beneficiary.email"
            class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Email</div>
            <div class="text-primary text-right">
              {{ beneficiary.email }}
            </div>
          </li>
          <li
            class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Asset</div>
            <div class="text-primary text-right">
              <asset-type :asset="beneficiary.currency" />
            </div>
          </li>
          <li
            v-if="beneficiary.type === 'CRYPTO' && beneficiary.details"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Blockchain</div>
            <div class="text-primary text-right capitalize">
              {{ beneficiary.details.blockchain }}
            </div>
          </li>
          <li
            v-if="beneficiary.type === 'CRYPTO' && beneficiary.details"
            class="w-full justify-between flex gap-x-10 items-start text-sm font-medium"
          >
            <div class="text-text-secondary">Address</div>
            <div
              class="text-primary flex flex-wrap justify-end max-w-[500px] gap-x-3 gap-y-2 items-center text-right break-all"
            >
              {{ beneficiary.details.address }}
              <button
                class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                @click="copyAddress(beneficiary.details.address)"
              >
                <copy-icon /> Copy Address
              </button>
            </div>
          </li>

          <div
            v-if="beneficiary.type === 'FIAT'"
            class="w-full flex flex-col gap-y-3"
          >
            <li
              v-if="beneficiary.details.type"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Payment method</div>
              <div class="text-primary text-right">
                {{
                  beneficiary.details.type === "FED_WIRE"
                    ? "WIRE"
                    : beneficiary.details.type
                }}
              </div>
            </li>

            <li
              v-for="item in Object.keys(beneficiary.details.details).filter(
                (it) =>
                  ![
                    'bank_address',
                    'intermediary_bank',
                    'bank_code',
                    'nip_code',
                  ].includes(it),
              )"
              :key="item"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                {{ convertToWords(item) }}
              </div>

              <div
                class="text-primary text-right"
                :class="
                  item === 'account_number' || item === 'routing_number'
                    ? 'flex items-center justify-end gap-x-1'
                    : ''
                "
              >
                {{ beneficiary.details.details[item] }}
                <button
                  v-if="item === 'account_number' || item === 'routing_number'"
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="copyAddress(beneficiary.details.details[item])"
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <div
              v-if="
                beneficiary.details?.details &&
                beneficiary.details?.details.bank_address &&
                typeof beneficiary.details?.details.bank_address === 'object'
              "
              class="w-full flex flex-col gap-y-3"
            >
              <p class="font-medium text-sm">Bank Address</p>
              <li
                v-for="item in Object.keys(
                  beneficiary.details.details.bank_address,
                )"
                :key="item"
                class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
              >
                <div class="text-text-secondary">
                  {{ convertToWords(item) }}
                </div>
                <div class="text-primary text-right">
                  {{
                    item === "country"
                      ? countryOf(
                          beneficiary.details.details.bank_address[
                            item as keyof TAddress
                          ] as string,
                        ).name
                      : beneficiary.details.details.bank_address[
                          item as keyof TAddress
                        ] || "N/A"
                  }}
                </div>
              </li>
            </div>
            <li
              v-else-if="beneficiary.details?.details?.bank_address"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Bank Address</div>
              <div class="text-primary text-right">
                {{ beneficiary.details.details.bankAddress }}
              </div>
            </li>
          </div>

          <div
            v-if="beneficiary.details?.details && intermediaryBankDetails.name"
            class="w-full flex flex-col gap-y-3 my-2"
          >
            <p class="font-medium text-sm">Intermediary Bank Details</p>

            <li
              v-if="intermediaryBankDetails.name"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Intermediary Bank Name</div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.name }}
              </div>
            </li>

            <li
              v-if="intermediaryBankDetails.swift_bic"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Intermediary Swift/BIC</div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.swift_bic }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.line_1
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address line 1
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.line_1 }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.line_2
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address line 2
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.line_2 }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.city
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address City
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.city }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.state
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address State
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.state }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.postal_code
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address Postal Code
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.postal_code }}
              </div>
            </li>

            <li
              v-if="
                intermediaryBankDetails.address &&
                intermediaryBankDetails.address.country
              "
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                Intermediary Bank Address Country
              </div>
              <div class="text-primary text-right">
                {{ intermediaryBankDetails.address.country }}
              </div>
            </li>
          </div>

          <div
            v-if="
              beneficiary.address && typeof beneficiary.address === 'object'
            "
            class="w-full flex flex-col gap-y-3 mt-2"
          >
            <p class="font-medium text-sm">Beneficiary Address</p>
            <li
              v-for="item in Object.keys(beneficiary.address)"
              :key="item"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                {{ convertToWords(item) }}
              </div>
              <div class="text-primary text-right">
                {{
                  item === "country"
                    ? countryOf(
                        beneficiary.address[item as keyof TAddress] as string,
                      ).name
                    : beneficiary.address[item as keyof TAddress] || "N/A"
                }}
              </div>
            </li>
          </div>
          <div
            v-else-if="
              beneficiary.address_details &&
              typeof beneficiary.address_details === 'object'
            "
            class="w-full flex flex-col gap-y-3 mt-2"
          >
            <p class="font-medium text-sm">Beneficiary Address</p>
            <li
              v-for="item in Object.keys(beneficiary.address_details)"
              :key="item"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">
                {{ convertToWords(item) }}
              </div>
              <div class="text-primary text-right">
                {{
                  item === "country"
                    ? countryOf(
                        beneficiary.address_details[
                          item as keyof TAddress
                        ] as string,
                      ).name
                    : beneficiary.address_details[item as keyof TAddress] ||
                      "N/A"
                }}
              </div>
            </li>
          </div>
          <li
            v-else-if="beneficiary.address"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Beneficiary Address</div>
            <div class="text-primary text-right">
              {{ beneficiary.address }}
            </div>
          </li>
        </ul>

        <app-button
          v-if="hasPermission(LyncPermissions.transactions_write)"
          variant="primary"
          size="lg"
          @click="handleSendToBeneficiary"
        >
          <airplane-icon class="fill-white" />
          Send Money
        </app-button>

        <button
          class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
          @click="closeModal"
        >
          <close-icon />
        </button>
      </div>
      <error-component
        v-if="isError"
        message="Error fetching this beneficiary"
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { useDefaultAsset, useDefaultBeneficiary } from "@/composables/states";
import { useGetBeneficiary } from "@/data-access/beneficiary";
import { copyToClipboard, convertToWords } from "@/helpers";
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppToast } from "@/composables";
import { TAddress } from "@/types";
import { countryOf } from "@/helpers/countries";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const toast = useAppToast();
const isModalOpen = ref(false);
const route = useRoute();
const router = useRouter();

const { hasPermission } = usePermission();

const {
  data: beneficiary,
  isLoading,
  isError,
} = useGetBeneficiary(route.params.id as string);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const intermediaryBankDetails = computed<Record<string, any>>(() => {
  if (beneficiary.value && beneficiary.value.details?.details) {
    return beneficiary.value.details.details.intermediary_bank || {};
  }

  return {};
});

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const { updateDefaultBeneficiary } = useDefaultBeneficiary();
const { updateDefaultAsset } = useDefaultAsset();

const closeModal = () => {
  isModalOpen.value = false;
  router.push("/beneficiaries");
};

const handleSendToBeneficiary = () => {
  if (beneficiary.value && route.query.type === "banking") {
    updateDefaultBeneficiary(beneficiary.value);
    router.push("/payments/send-money");
  } else if (beneficiary.value && beneficiary.value.type === "CRYPTO") {
    updateDefaultBeneficiary(beneficiary.value);
    updateDefaultAsset({
      currency: beneficiary.value.currency,
      network: beneficiary.value.details?.blockchain || "",
    });
    router.push("/payments/send-stablecoins");
  }
};

onMounted(() => {
  isModalOpen.value = true;
});
</script>
