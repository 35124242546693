<template>
  <form class="flex flex-col gap-y-3 px-5 py-5" @submit="onSubmit">
    <app-select
      label="Role"
      name="role"
      v-bind="formFields.role"
      :error-message="errors.role"
      placeholder="Enter position"
    >
      <option value="" disabled>Select a role</option>
      <option
        v-for="it in roles.filter((it) => it.id !== 'OWNER')"
        :key="it.id"
        :value="it.id"
      >
        {{ it.name }}
      </option>
    </app-select>
    <app-button
      :loading="submitting"
      :disabled="submitting"
      type="submit"
      size="sm"
      variant="primary"
    >
      Update role
    </app-button>
  </form>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { RolesResponse } from "@/types";
import { useForm } from "vee-validate";
import { reactive } from "vue";
import { useAppToast } from "@/composables";
import * as yup from "yup";

const props = defineProps<{
  businessUserId: string;
  refetch: () => void;
  closeModal: () => void;
  roles: RolesResponse[];
}>();

interface FormFields {
  role: string;
}

const toast = useAppToast();

const { submitting, execute: updateUserRole } = useWriteResource(
  `/users/${props.businessUserId}/roles`,
  "patch",
  {
    successTitle: "Role updated successfully",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { errors, handleSubmit, defineInputBinds } = useForm<FormFields>({
  validationSchema: yup.object({
    role: yup.string().required("Role is required"),
  }),
});

const onSubmit = handleSubmit(async (values) => {
  await updateUserRole({
    body: {
      role: values.role,
    },
  });
  props.refetch();
  props.closeModal();
});

const formFields = reactive({
  role: defineInputBinds("role"),
});
</script>
