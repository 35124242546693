import { ToastProps, useToast } from "vue-toast-notification";

export const useAppToast = () => {
  const toast = useToast({
    duration: 6000,
    position: "top-right",
  });

  const error = (message: string, options?: ToastProps) => {
    toast.error(message, options);
  };

  const success = (message: string, options?: ToastProps) => {
    toast.success(message, options);
  };

  const info = (message: string, options?: ToastProps) => {
    toast.info(message, options);
  };

  return { error, success, info };
};
